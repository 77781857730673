<template>
  <div>
    <v-card-text :style="` width: 100%; height: ${height}px; overflow: auto`" class="pa-0">
      <Kanban :columns.sync="columns" col-min-width="350" col-max-width="450" count-text="элементов" drop-text1="Переместите сюда" :readonly="true" :height="height">
        <template v-slot:card="{ item }">
          <div class="item" :style="{ 'border-left-color': 'green' }">
            <div class="kanban-card">
              <MessageCard :item="item" @show-card="onClick($event)" :model="model" :key="item.id" @click-create="createMessage($event)" />
            </div>
          </div>
        </template>
      </Kanban>
      <v-fab-transition v-if="canAdd">
        <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createMessage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
    <portal to="v-main">
      <MessageEdit
        v-model="showEditDialog"
        v-if="showEditDialog"
        :api="api"
        :m="m"
        :id="idEdit"
        :data="data"
        :init-data="messageInitData"
        :messages="messages"
        @createMessage="createMessage($event)"
      />
    </portal>
  </div>
</template>

<script>
import Kanban from "./kanban.vue";
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    Kanban,
    MessageCard: () => import("./messageCard"),
    MessageEdit: () => import("./../dialogs/messageEdit"),
  },
  props: {
    height: { type: Number, default: 300 },
    data: Object,
  },
  data() {
    return {
      showEditDialog: false,
      columns: [],
      messages: [],
      idEdit: 0,
      m: this.$store.getters["config/get"].models.education.message,
      messageInitData: null,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
        this.idEdit = 0;
        this.messageInitData = null;
        this.$emit("refresh");
      }
    },
  },
  computed: {
    api() {
      return this.m.api;
    },
    model() {
      let model = this.getModelList(this.m, "form");
      return model;
    },
    canAdd() {
      return true;
    },
  },
  methods: {
    async initData() {
      this.columns = [];
      await this.fitchData();
    },
    async fitchData() {
      try {
        let params = {
          filters: { education_chain_id: this.data.id },
        };
        let d = await this.$axios.get(this.api, { params });
        let data = [...d.data.data];
        this.fillColumns(data);

        this.messages = [...data];
      } catch (error) {
        this.$root.$emit("show-info", { text: "Ошибка загрузки данных" });
      }
    },
    fillColumns(data, type = "clear") {
      let columns = [];
      if (type == "clear") {
        data
          .filter((d) => !d.from_id && d.next_id)
          .forEach((c) => {
            columns.push({
              id: c.id,
              name: c.id,
              color: c.color,
              columnItems: [],
              type: c.type,
            });
          });
        this.columns = [];
        columns.push({
          id: 0,
          name: "Свободные",
          columnItems: [],
          type: 0,
        });
      }
      const fill = (data) => {
        let res = 0;
        for (let i = 0; i < columns.length; i++) {
          let id;
          if (columns[i].columnItems.length) {
            id = columns[i].columnItems.at(-1).id;
          }
          id = id ? id : columns[i].id;
          //  console.log("find ", id, data);
          data
            .filter((d) => (d.id == id || (d?.from_id || 0) == id) && !d.done)
            .forEach((d) => {
              //console.log(`push ${d.id} in ${id}`, d);
              d["done"] = true;
              columns[i].columnItems.push(d);
              res++;
            });
        }
        return res;
      };
      let t = 1;
      while (t > 0) {
        t = fill(data.filter((d) => !d.done));
      }
      columns.push({
        id: -1,
        name: "Error",
        columnItems: data.filter((d) => !d.done),
        type: -1,
      });

      this.columns = columns;
    },

    createMessage({ from_id, next_id }) {
      this.showEditDialog = !true;
      this.idEdit = 0;
      console.log("{ from_id, next_id }: ", { from_id, next_id });
      this.messageInitData = { education_chain_id: this.data.id, from_id, next_id };
      this.showEditDialog = true;
    },
    onClick(d) {
      console.log("on click", d);
      this.idEdit = d.id;
      this.showEditDialog = true;
    },
  },
};
</script>
<style scoped>
.kanban {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  user-select: none;
}
.kanban__move-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 0.9rem;
  color: #b0b0b0;
  user-select: none;
}
.kanban__move-icon span {
  margin-left: 10px;
}
.kanban__move-icon svg {
  width: 20px;
  height: 20px;
}
.kanban__drop-preview {
  position: relative;
  margin: 10px;
  background-color: #edf1f3;
  border: 2px dashed rgba(0, 0, 0, 0.059);
  border-radius: 4px;
}
.kanban__drop-preview::before {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #b0b0b0;
  transform: translate(-50%, -50%);
}
.kanban__drop-preview::after {
  position: absolute;
  top: 50%;
  left: 27%;
  width: 20px;
  height: 20px;
  content: "";
  transform: translate(-50%, -50%);
}
.kanban__description {
  display: flex;
  align-items: center;
}
.kanban__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf5f7;
  padding: 0.5rem;
  border-radius: 8px;
}
.kanban__title {
  margin: 0 0 0 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}
.kanban__quantity {
  margin-top: 0;
  font-size: small;
  color: #b0b0b0;
  text-align: right;
}
.kanban__quantity--inner-count {
  margin: 1rem 1rem 0 1rem;
  padding-top: 0.5rem;
}
.kanban__column {
  flex: 1;
  height: fit-content;
  padding: 10px;
  border-radius: 6px;
  transition: height 0.5s ease;
  border: 1px solid #e0e0e0;
}
.kanban__column:not(:first-of-type) {
  margin-left: 15px;
}
.kanban__column--no-border {
  border: none;
}
.kanban__header {
  margin-bottom: 15px;
  background-color: #fff;
}
.kanban__content {
  position: relative;
}
.kanban__skeleton {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-right: 15px;
  background-color: #f8f8f8;
  border-radius: 4px;
  overflow: auto;
}
.kanban__skeleton-container {
  width: 100%;
}
.kanban__skeleton-column {
  flex: 1;
  height: calc(100% - 30px);
  padding: 10px;
  margin-left: 15px;
  background-color: #e9e9e9;
  border-radius: 6px;
}
.kanban__skeleton-card {
  position: relative;
  height: 100px;
  padding: 15px;
  margin-top: 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.kanban__skeleton-card--one {
  position: absolute;
  top: 8px;
}
.kanban__skeleton-card--two {
  position: absolute;
  top: 8px;
  right: 10px;
}
.kanban__skeleton-card--three {
  position: absolute;
  top: 30px;
}
.kanban__skeleton-card--four {
  position: absolute;
  bottom: 2rem;
}
.kanban__skeleton-card--five {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
}

.item,
.draggable-item,
.draggable-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 8px;
  cursor: pointer;
  user-select: none;
  background-color: white;
  border: 1px solid transparent;
  border-left: 5px solid #e0e0e0;
  border-radius: 4px;
  transition: border-color 0.2s linear;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
}
.item .card {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.item .card small {
  color: #838383;
}
.item.open {
  border-left: 5px solid #f3c1c1;
}
.item.executing {
  border-left: 5px solid #c4ebaf;
}
.item.finished {
  border-left: 5px solid #b3d5e9;
}
.item.paralised {
  border-left: 5px solid #dc3545;
}
.item.review {
  border-left: 5px solid #e0e0e0;
}
.badge {
  margin: 1rem 0;
  cursor: pointer;
}
.label {
  color: #838383;
  margin-bottom: 5px;
}
.kanban-action {
  display: flex;
  justify-content: space-between;
}

.kanban-button {
  max-width: 130px;
  pointer-events: none;
  border: none;
  color: #f7f7f7;
  padding: 1px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}

.kanban-button.open {
  background-color: #f3c1c1;
}
.kanban-button.executing {
  background-color: #c4ebaf;
}
.kanban-button.finished {
  background-color: #b3d5e9;
}
.kanban-button.paralised {
  background-color: #dc3545;
}
.kanban-button.review {
  background-color: #e0e0e0;
}

.kanban-cards {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
</style>
